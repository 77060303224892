import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {StepZero} from "../pages/StepZero";
import {StepZero1} from "../pages/StepZero1";
import {StepOne} from "../pages/StepOne";
import {StepTwo} from "../pages/StepTwo";
import {StepTwo1} from "../pages/StepTwo1";
import {StepTwo2} from "../pages/StepTwo2";
import {Package} from "../pages/Package";
import {StepTwo3} from "../pages/StepTwo3";
import {StepThree} from "../pages/StepThree";
import {StepFour} from "../pages/StepFour";
import {StepFive} from "../pages/StepFive";
import {StepSix} from "../pages/StepSix";
import {StepSeven} from "../pages/StepSeven";
import {StepEight} from "../pages/StepEight";
import {Result} from "../pages/Result";
import {Tutorial} from "../pages/Tutorial";
import {ReportTutorial} from "../pages/ReportTutorial";
import {Intro} from "../pages/Intro";
import {Intro1} from "../pages/Intro1";
import {Intro2} from "../pages/Intro2";
import {Donate} from "../pages/Donate";
import {Donate2} from "../pages/Donate2";
import {Donate3} from "../pages/Donate3";
import {NoteScan} from "../pages/NoteScan";
import {NoteLogin} from "../pages/NoteLogin";
import {CreateSale} from "../pages/CreateSale";

const Routes: FC = () => {
  return (
    <Switch>
      <Route path='/intro2' component={Intro2} />
      <Route path='/' component={CreateSale} />
    </Switch>

  )
}

export {Routes}
